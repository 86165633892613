<template>
  <div class="flex flex-col px-10">
    <div class="flex items-start justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between items-start py-4">
        <div class="mt-1 flex flex-col justify-between">
          <h2
            class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Inventaire : [ {{ inventory.code }}] {{ inventory.name }}
          </h2>
          <div class="flex flex-col">
            <div class="flex items-center">
              <span>Date :</span>
              <span class="ml-3">{{ inventory.date }}</span>
            </div>
            <div class="flex items-center">
              <span>Créer par:</span>
              <span class="ml-3">{{ inventory.create_by }}</span>
            </div>
            <div class="flex items-center">
              <span>Date clôture:</span>
              <span class="ml-3">{{ inventory.close_date }}</span>
            </div>
            <div class="flex items-center">
              <span>Statut:</span>
              <span class="ml-3"> <Status :name="inventory.status" /></span>
            </div>
            <div class="flex items-center">
              <span>Entrepôt:</span>
              <span class="ml-3">
                [{{ inventory.warehouse_code }}]
                {{ inventory.warehouse_name }}</span
              >
            </div>
          </div>
        </div>
        <div class="flex items-center text-sm text-gray-400">
          <el-dropdown class="mr-2" @command="handleExport">
            <el-button :loading="exportLoading">
              Exporter <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="PDF">PDF</el-dropdown-item>
              <el-dropdown-item command="EXCEL">EXCEL</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            icon="el-icon-delete"
            v-if="
              inventory.status !== 'CANCELED' && inventory.status !== 'CLOSED'
            "
            type="danger"
            @click="HANDLE_CANCELED_INVENTORY"
            >Annuler
          </el-button>
          <el-button
            type="primary"
            v-if="inventory.status === 'OPEND'"
            @click="HANDLE_CONFIRMED_INVENTORY"
            >Confirmer
          </el-button>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full justify-between items-center">
      <div class="flex w-full bg-white flex-col border p-3 pt-8">
        <div class="flex flex-row mb-4">
          <el-input
            class="mr-4"
            placeholder="Recherche par le nom"
            v-model="listQuery.articleName"
            @keydown.native.enter="FETCH_ITEM_INVENTORY()"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-input
            class="mr-4"
            placeholder="Code barre "
            v-model="listQuery.articleBareCode"
            @keydown.native.enter="FETCH_ITEM_INVENTORY()"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>

          <el-select
            class="mr-4"
            style="width: 40%"
            placeholder="Categorie"
            v-model="listQuery.categoryId"
            @change="FETCH_ITEM_INVENTORY()"
            clearable
          >
            <el-option
              v-for="(item, index) in categories"
              :key="index"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
          <el-button icon="el-icon-search">Rechecher</el-button>
        </div>

        <div class="customer-white">
          <skeleton-list :row="8" v-if="listLoading" />
          <el-table :data="items" v-else style="width: 100%">
            <el-table-column
              prop="article_name"
              label="CODE ARTICLE"
              width="210"
            >
              <template slot-scope="{ row }">
                <span>
                  {{ row.article_sku }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="article_name" label="CATEGORIE" width="210">
              <template slot-scope="{ row }">
                <span>
                  {{ row.category }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="article_name"
              label="DÉSIGNATION ARTICLE"
              min-width="210"
            >
              <template slot-scope="{ row }">
                <div class="input-transparent flex justify-start items-center">
                  {{ row.article_name }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="quantity"
              align="center"
              label="QTE EN STOCK"
              width="150"
            >
              <template slot-scope="{ row }">
                <span>
                  {{ row.quantity_th }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              align="center"
              label="QTE INVENT"
              width="150"
            >
              <template slot-scope="{ row }">
                <div
                  v-if="inventory.status === 'OPEND'"
                  class="table flex items-center"
                >
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="row.quantity_ph"
                    @blur="UPDATE_ITEM_INVENTORY(row)"
                  >
                  </el-input-number>
                </div>
                <span v-else> {{ row.quantity_ph }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              align="center"
              label="DIFF"
              width="120"
            >
              <template slot-scope="{ row }">
                <span>
                  {{ row.quantity_ph - row.quantity_th }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              align="right"
              label="COÛT D'ACHAT"
              width="130"
            >
              <template slot-scope="{ row }">
                <span>
                  {{ row.price | moneyFilter }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              align="right"
              label="MONTANT INVENT"
              width="150"
            >
              <template slot-scope="{ row }">
                <span>
                  {{ (row.price * row.quantity_ph) | moneyFilter }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="flex justify-end">
            <pagination
              v-show="listQuery.total > 0"
              :total="listQuery.total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.size"
              @pagination="FETCH_ITEM_INVENTORY()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getItemsInventaire,
  getInventaireByNumero,
  confirmedInventory,
  canceledInventory,
  updateItemInventory,
} from "@/api/stock";
import { exportInventoryItemExcel, exportInventoryItemPDF } from "@/api/export";
import { getCategories } from "@/api/article";
import Pagination from "@/components/Pagination";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

export default {
  name: "Fiche",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      dialogFormVisible: false,
      loading: false,
      removeLoading: false,
      categories: [],
      listLoading: false,
      exportLoading: false,
      inventoryItem: {
        quantity_expected: 0,
        quantity_actual: 0,
        description: "",
        stock_inventory_numero: 0,
        cost_inventory: null,
        article_id: null,
      },
      articleItems: [],
      inventory: {
        close_date: undefined,
        code: "",
        create_by: "",
        date: "",
        name: "",
        raison: "",
        status: "",
        warehouse_code: "",
        warehouse_name: "",
      },
      loadingArticle: false,

      articles: [],
      items: [],
      currentRowId: null,
      stats: {
        count_items: 0,
        total_physique: 0,
        total_theorique: 0,
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 100,
        sort: "asc",
        articleName: undefined,
        articleBareCode: undefined,
        categoryId: undefined,
        sortDir: "articleName",
      },
    };
  },
  mounted() {
    this.getInventory();
    this.FETCH_ITEM_INVENTORY();
    this.fetchCategories();
    //this.FETCH_ARTICLE_INVENTORY();
    /*  this.FETCT_STATS_INVENTORY();
    this.getInventory();
    */
  },
  methods: {
    handleAddItem() {
      this.dialogFormVisible = true;
    },

    clearCategory() {
      this.listQuery.category = undefined;
    },

    async fetchCategories() {
      await getCategories()
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getInventory() {
      const numero = this.$route.params && this.$route.params.id;
      await getInventaireByNumero(numero)
        .then((res) => {
          this.inventory = Object.assign({}, res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      681;
    },
    async UPDATE_ITEM_INVENTORY(row) {
      if (row.quantity_ph_last !== row.quantity_ph) {
        const numero = this.$route.params && this.$route.params.id;
        const item = {
          item_id: row.id,
          inventory_code: numero,
          quantity: row.quantity_ph,
        };
        updateItemInventory(item)
          .then(() => {
            row.quantity_ph_last = row.quantity_ph;
            this.$message({
              type: "success",
              message: "Quantité modifier avec succès ",
            });
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err.data.message,
            });
          });
      }

      //await
    },

    async FETCH_ITEM_INVENTORY() {
      this.listLoading = true;
      const numero = this.$route.params && this.$route.params.id;
      await getItemsInventaire(this.listQuery, numero).then((res) => {
        setTimeout(() => {
          const inventories = res.data.items;
          this.items = inventories.map((el) => {
            this.$set(el, "quantity_ph_last", el.quantity_ph);
            return el;
          });
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },

    HANDLE_CONFIRMED_INVENTORY() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Confirmer la clôture",
        message: h("p", null, [
          h("span", null, "Vous souhaitez clôturé cet inventaire ? N°:  "),
          h("span", { style: "color: teal" }, this.inventory.code),
        ]),
        closeOnClickModal: false,
        showClose: false,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Encours...";
            const numero = this.$route.params && this.$route.params.id;
            // fonction confirmer
            confirmedInventory(numero)
              .then((res) => {
                setTimeout(() => {
                  console.log(res.data);
                  done();
                  instance.confirmButtonLoading = false;
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  done();
                  console.log(err);
                  instance.confirmButtonLoading = false;
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {
        this.inventory.status = "IN_PROGRESS";
        this.$message({
          type: "success",
          message: "L'inventaire est en cours de traitement ... ",
        });
      });
    },

    HANDLE_CANCELED_INVENTORY() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Confirmer l'annulation",
        message: h("p", null, [
          h("span", null, "Vous souhaitez annuler cet inventaire ? rappel   "),
          h("span", { style: "color: teal" }, this.inventory.code),
        ]),
        closeOnClickModal: false,
        showClose: false,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Encours...";
            const numero = this.$route.params && this.$route.params.id;
            // fonction confirmer
            canceledInventory(numero)
              .then((res) => {
                setTimeout(() => {
                  console.log(res.data);
                  done();
                  instance.confirmButtonLoading = false;
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  done();
                  console.log(err);
                  instance.confirmButtonLoading = false;
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {
        this.inventory.status = "CANCELED";
        this.$message({
          type: "success",
          message: "L'inventaire annulé avec success ",
        });
      });
    },
    exportInventoryExcel() {
      this.exportLoading = true;
      exportInventoryItemExcel(this.inventory.code, this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inventaire-" + this.inventory.code + ".xls"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportInventoryPDF() {
      this.exportLoading = true;
      exportInventoryItemPDF(this.inventory.code, this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inventaire-" + this.inventory.code + ".pdf"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleExport(e) {
      switch (e) {
        case "PDF":
          this.exportInventoryPDF();
          break;
        case "EXCEL":
          this.exportInventoryExcel();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
